import { WindowLocation } from '@reach/router';

import { BasicPage } from '@components/layout';

type HomeRecoverVariationProps = {
  location: WindowLocation;
};

export const HomeRecoverVariation = ({ location }: HomeRecoverVariationProps) => {
  return (
    <BasicPage
      pageName="home-recover"
      quote={undefined}
      location={location}
      shouldIncludeFootnotes={false}
    >
      home recover variation
    </BasicPage>
  );
};
