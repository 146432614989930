import { PageProps } from 'gatsby';

import { ModalContextProvider } from '@contexts/ModalContext';
import { HomeRecoverOverview } from '@src/modules/HomeRecoverOverview/HomeRecoverOverview';
import ABTest from '@components/ABTest';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { useAuth } from '@hooks/useAuth';
import { HomeRecoverVariation } from '@src/modules/HomeRecoverVariation/HomeRecoverVariation';

export const HomeRecover = (props: PageProps) => {
  const { user } = useAuth();

  return (
    <ModalContextProvider>
      <ABTest
        experimentName={OptimizelyFeatureFlag.HOME_RECOVER_LANDING_PAGE}
        accountId={user?.accountId}
      >
        {(flagEnabled, variationKey) => {
          const isVariation = flagEnabled && variationKey === 'variation';

          return isVariation ? (
            <HomeRecoverVariation {...props} />
          ) : (
            <HomeRecoverOverview {...props} />
          );
        }}
      </ABTest>
    </ModalContextProvider>
  );
};

export default HomeRecover;
